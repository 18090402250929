import logo from '../content/SeasonalLogo.png';
import logoText from '../content/deadfolk_text.png';

const Home = () =>
{
    return (
        <div className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <img src={logoText} className="App-logo-text" alt="logo-text" />
        </div>
    );
}

export default Home;