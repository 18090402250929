import './Card.css'

export function Card(props)
{
    return (
        <div className="Card">
            <div className="element">
                {props.picture && <div className='icon'><img src={props.picture} /></div>}
                {props.svg && <div className='icon'>{props.svg}</div>}
                <div className="name">{props.name}</div>
                <div className="role">{props.role}</div>
                {props.url && <div className='url'><a href={props.url}>{props.url}</a></div>}
                <div className="desc">{props.desc}</div>
            </div>
            <div className="dummy" />
        </div>
    );
}