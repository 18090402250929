import './About.css';
import { Card } from '../components/Card'
import logo from '../content/SeasonalLogo.png';

let deadfolkDescription = `
Welcome to DEADFOLK, a game studio founded by industry veterans Matt Dean and Wyatt Sander, headquartered in the vibrant city of Seattle, Washington, USA. Combining for 25 years of experience, Matt and Wyatt bring a wealth of expertise and a passion for game and software development. With our extensive background of prior work, including a combined 10 years at Unity, we are experts in one of the industry's leading game development platforms.

Now, at DEADFOLK, they are dedicated to crafting unique gaming experiences that resonate with players across various genres. Here at DEADFOLK, we are committed to exploring the diverse landscape of gaming, creating titles for mobile, desktop, and console platforms.

While we take pride in developing our own original games, DEADFOLK is also your go-to partner for realizing the potential of your own projects. Our team is open to collaboration, ready to leverage our experience to bring your unique vision to life. Whether you're a fellow developer, publisher, or business seeking top-notch game or software development services, DEADFOLK is here to deliver outstanding results.
`;

const About = () =>
{
    return (
        <div className="App-header">
            <div className='title'>WHO WE ARE</div>
            <div className="desc">{deadfolkDescription}</div>
            <div className="card-grid">
                <Card name="MATT" role="Cofounder" picture={logo} />
                <Card name="WYATT" role="Cofounder" picture={logo} />
            </div>
        </div>
    );
}

export default About;